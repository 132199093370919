import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';
import ReactModalVideo from 'react-modal-video';
import { generateUniqueKey } from 'lib/helpers';

import './ModalVideo.scss';

/**
 * Modal Vieo.
 */
const propTypes = {
  // channel: PropTypes.string,
  // isOpen: PropTypes.bool,
  // videoId: PropTypes.string.isRequired,
  // youtube: PropTypes.object, // youtube player options.
  // wistia: PropTypes.object, // wistia player options.
}
const ModalVideo = ( { youtube, ...p } ) => {

  const id = generateUniqueKey( 4 );

  /**
   * Toggle open.
   */
  const handleClick = e => {


    if( e.target.closest( `.wistia_embed` ) ) {
      return;
    }

    p.onClose();

  }


  /* ------ = Lifecycle. = --------------------------------------------------------------------- */

  // React.useEffect( () => {
  //
  //   console.log( 'kmdsfkmdskfm' );
  //   setIsOpen( p.isOpen );
  // }, [ p.isOpen ] )

  React.useEffect( () => {

    const modal = document.getElementById( id );
    if( p.isOpen || p.isPlaying  ) {
      modal.addEventListener( 'click', handleClick );
    }
    else {
      modal.removeEventListener( 'click', handleClick );
    }

  }, [ p.isOpen, p.isPlaying ] );


  return(

    <div id={ id }>

      { ( p.isOpen || p.isPlaying ) &&

        <div
        className="ModalVid modal-video"
        tabindex="-1"
        role="dialog"
        aria-label="You just opened a modal playing a video."
        >
          <div className="Modal__body modal-video-body">
            <div className="inner modal-video-inner">

              <div className="ModalVid__vidwrap modal-video-movie-wrap">
                <ReactPlayer
                url={ p.url }
                playing={ p.isPlaying }
                />
              </div>

            </div>
          </div>

        </div>
      }

    </div>

  )

};
ModalVideo.propTypes = propTypes;

export default ModalVideo;





// const ModalVideo = ( { youtube, ...p } ) => {
//
//   const id = generateUniqueKey( 4 );
//
//   React.useEffect( () => {
//
//     if( ! p.isOpen ) {
//       return;
//     }
//
//     const iframe = document.getElementById( id ).querySelector( 'iframe' );
//
//     let src = iframe.src;
//
//     setTimeout( () => {
//       iframe.src = src.replace( 'mute=true', 'mute=0' );
//     }, 500 )
//
//
//
//   }, [ p.isOpen ] )
//
//   const options = {
//     autoplay: true,
//     mute: p.isOpen ? true : false,
//   }
//
//   return(
//
//     <div id={ id }>
//       <ReactModalVideo
//       id={ id }
//       { ...p }
//       />
//     </div>
//
//   )
//
// };
// ModalVideo.propTypes = propTypes;
//
// export default ModalVideo;



